










import RegisterSiteForm from '../components/sites/RegisterSiteForm.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'RegisterSitePage',

  components: {
    RegisterSiteForm,
  },
});
