





































































































import { AuthService } from '@/services/auth/AuthService';
import Vue from 'vue';
import { VForm } from '../../plugins/vuetifyTypes';
import { EmailService } from '../../services/email/EmailService';
import { SnackbarType } from '../../services/snackbar/models/SnackbarType';
import { SnackbarService } from '../../services/snackbar/SnackbarService';
import { formValidator } from '../../utils/FormValidator';

export default Vue.extend({
  name: 'RegisterSiteForm',

  data() {
    return {
      formValidator,
      isLoading: false,
      isRegisterSiteSuccessful: false,
      formData: {
        serialNumber: '',
        siteName: '',
        address: '',
        zip: '',
        city: '',
        country: '',
      },
    };
  },

  methods: {
    async onSubmit(): Promise<void> {
      this.isLoading = true;
      try {
        const validation = (this.$refs.form as VForm).validate();
        if (validation) {
          const token = AuthService.getToken();
          const form = { ...this.formData, token };
          await EmailService.sendMail({ form });
          this.isRegisterSiteSuccessful = true;
          SnackbarService.open(
            this.$t('snackbar.registered', { name: this.formData.siteName }),
            SnackbarType.Success
          );
        } else {
          this.isLoading = false;
        }
      } catch (error) {
        SnackbarService.open(
          this.$t('snackbar.somethingWentWrong'),
          SnackbarType.Error
        );
        this.isLoading = false;
        this.isRegisterSiteSuccessful = false;
      }
    },
  },
});
