import { AxiosInstance } from '../../api/axios';
import { AuthService } from '../auth/AuthService';
import { EmailServiceInterface } from './EmailServiceInterface';

class EmailServiceImplementation implements EmailServiceInterface {
  async sendMail(data: object): Promise<void> {
    const ENDPOINT = '/sendmail';
    const user = AuthService.getCurrentUser();

    await AxiosInstance.post(
      ENDPOINT,
      { user, ...data },
      {
        baseURL: '',
        headers: {
          authorization: AuthService.getToken(),
          'x-auth-token': AuthService.getToken(),
        },
      }
    );
  }
}

export const EmailService = new EmailServiceImplementation();
